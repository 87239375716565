<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            /
            <!-- <router-link :to="{name: 'admin-master' }" class="root">
               Librarian
            </router-link> -->
            <span class="child"> Librarian / Add Book Location </span>
        </v-row>
        <br />

        <v-card class="card">
            <v-card-title class="heading justify-center"> Add Book Location</v-card-title>

            <div class="">
                <center>
                    <v-col lg="5">
                        <label style="font-weight: bold; color: blue;">Select Accession Number</label>
                        <v-combobox
                            v-model="accessionNo"
                            :items="accessionNoList"
                            item-text="accession_number"
                            item-value="accession_number"
                            outlined
                            return-object
                            dense
                            :rules="[rules.required]"
                            clearable
                            required
                            @change="findBookInfo(accessionNo)"
                        ></v-combobox>
                    </v-col>
                </center>
                <div v-if="userInfo">
                    <div class="cus-card">
                        <v-row>
                            <v-col lg="2"> <label class="c-label">Title</label></v-col>
                            <v-col><span>: {{book_info.title}}</span></v-col>
                            <v-col lg="3"> <label class="c-label">Author(s)</label></v-col>
                            <v-col><span v-for="(item,index) in book_info.authors" :key="index">: {{item}}</span></v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="2"> <label class="c-label">Category</label></v-col>
                            <v-col><span>: {{book_info.category}}</span></v-col>
                            <v-col lg="3"> <label class="c-label">Publisher</label></v-col>
                            <v-col><span>: {{book_info.publisher}}</span></v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="2"> <label class="c-label">Book Type</label></v-col>
                            <v-col><span>: {{book_info.bookType}}</span></v-col>
                            <v-col lg="3"> <label class="c-label">Book Format</label></v-col>
                            <v-col><span>: {{book_info.bookFormat}}</span></v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="2"> <label class="c-label">Book Price</label></v-col>
                            <v-col><span>: {{book_info.price}}</span></v-col>
                            <v-col lg="3"> <label class="c-label">No of Copies available</label></v-col>
                            <v-col><span>: {{book_info.copiesAvailable}}</span></v-col>
                        </v-row>
                    </div>
                    <center v-if="!isRackAvailable">
                        <div>
                            <p class="blink_me mb-0">Selected Rack and Block not available!!!</p>
                            <p class="blink_me mt-0">Please select another rack.</p>
                        </div>
                    </center>
                    <v-row style="justify-content: center;">
                        <v-col lg="3">
                            <label>Rack No</label>
                            <v-autocomplete dense outlined @change="getRackBlockNo()" :items="racklist" item-value="rackId" item-text="rackNo" v-model="rackId" persistent-hint></v-autocomplete>
                        </v-col>
                        <v-col lg="3">
                            <label>Block No</label>
                            <v-autocomplete dense outlined @change="checkRackAvailability" :items="blockList" item-text="block_no" item-value="block_no" v-model="block_no" persistent-hint></v-autocomplete>
                        </v-col>
                    </v-row>
                    <center>
                        <div>
                            <p>Category:<span class="c-label"> {{rackCat}}</span></p>
                        </div>
                        <v-btn color="success" class="m-3" :disabled="!isRackAvailable" @click="addBookLocation">Add Book Location</v-btn>
                    </center>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            valid: false,
            overlay: false,
            userid: "",
            bookitemid: "",
            block_no: null,
            errorMsg: {
                roleLinkId: false,
            },
            rules: {
                required: (value) => !!value || "Required.",
            },
            snackbar_msg: "",
            color: "",
            snackbar: false,
            rackCat: "",
            blockno: null,
            search: "",
            rackId: null,

            accessionNoList: [],
            book_info: [],
            bookinformation: [],
            empmemberbooklendinginfo: [],
            allBookInfo: [],
            accessionNo: "",
            selectedbookitem: "",
            org: "",
            inSave: true,
            dialog: false,
            bookCategoryList: [],
            roleLink_list: [],
            role_list: [],
            userInfo: false,
            bookInfo: false,
            racklist: null,
            isRackAvailable: true,
            blockList: [],
        }),

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "New Book" : "Edit Book";
            },
        },

        // watch: {
        //     dialog(val) {
        //         val || this.close();
        //     },
        //     overlay(val) {
        //         val = () => {
        //             this.overlay = false;
        //         };
        //     },
        // },
        mounted() {
            this.onLoad();
        },
        methods: {
            getRackBlockNo() {
                const data = {
                    rackId: this.rackId,
                    block_no: this.block_no,
                };
                axios
                    .post("/Librarian/getRackBlockNo", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.blockList = res.data.blockList;
                            this.isRackAvailable = res.data.isRackAvailable;
                        } else if (res.data.status.code == "NA") {
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        }
                    })
                    .catch((error) => {
                        window.console.log(error);
                    });
            },
            checkRackAvailability() {
                const data = {
                    rackId: this.rackId,
                    block_no: this.block_no,
                };
                axios
                    .post("/Librarian/checkRackAvailability", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.isRackAvailable = res.data.isRackAvailable;
                        } else if (res.data.status.code == "NA") {
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        }
                    })
                    .catch((error) => {
                        window.console.log(error);
                    });
            },
            findBookInfo(a_no) {
                console.log(a_no);
                this.overlay = true;
                this.accessionNo = a_no.a_no;

                axios
                    .post("/Librarian/getBookInfo", a_no)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.userInfo = true;
                            this.book_info = res.data.book_info;
                            this.racklist = res.data.racklist;
                            this.blockList = res.data.block_list;
                            this.rackCat = res.data.book_info.rackCat;
                            this.rackId = res.data.book_info.rackId;
                            this.block_no = res.data.book_info.block_no;
                            // alert(this.blockno )
                            // this.empmemberbooklendinginfo = res.data.booklendinginformation;
                            // this.onLoad()
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                        // var overlay = false;
                    });
            },

            addBookLocation() {
                const data = {
                    a_no: this.accessionNo,
                    rackNo: this.rackId,
                    blockNo: this.block_no,
                };
                console.log("data-------", data);
                this.overlay = true;
                axios
                    .post("/Librarian/addBookLocation", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.showSnackbar("#4caf50", "Book location added Successfully");
                            this.overlay = false;
                            this.onLoad();
                            this.accessionNo = null;
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {});
            },
            onLoad() {
                this.overlay = true;
                axios
                    .get("/Librarian/getA_noList")
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.overlay = false;
                            this.accessionNoList = res.data.a_noList;
                            this.userInfo = false;
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
                        window.console.log(error);
                    })
                    .finally(() => {
                        // var overlay = false;
                    });
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            close() {
                this.dialog = false;
                // this.$refs.form.reset()
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                }, 300);
            },

            save() {
                this.editedItem.displayOrder = parseInt(this.editedItem.displayOrder);
                this.editedItem.capacity = parseInt(this.editedItem.capacity);
                if (this.editedIndex > -1) {
                    if (this.$refs.form.validate()) {
                        axios
                            .post("/Admin/editRack", this.editedItem)
                            .then((res) => {
                                if (res.data.status.code == "SUCCESS") {
                                    //window.alert(res.data.msg)
                                    Object.assign(this.roleLink_list[this.editedIndex], this.editedItem);
                                    this.showSnackbar("#4caf50", "Rack Updated Successfully..."); // show snackbar on success
                                    this.onLoad();
                                } else if (res.data.status.code == "NA") {
                                    this.showSnackbar("#b71c1c", "Rack Already Present!!!"); // show snackbar on error
                                }
                            })
                            .catch((error) => {
                                window.console.log(error);
                            });
                        this.close();
                    }
                } else {
                    // this.roleLink_list.push(this.editedItem)
                    axios
                        .post("/Admin/saveRack", this.editedItem)
                        .then((res) => {
                            if (res.data.status.code == "SUCCESS") {
                                this.showSnackbar("#4caf50", "Rack Added Successfully!!!"); // show snackbar on success
                                this.onLoad();
                            } else if (res.data.status.code == "NA") {
                                this.showSnackbar("#b71c1c", "Rack Already Present!!!"); // show snackbar on error
                            }
                        })
                        .catch((error) => {
                            window.console.log(error);
                        });
                    this.close();
                }
            }, //save()

            activeOn(item) {
                axios
                    .post("/admin/rackIsactive", item)
                    .then(() => {
                        // this.sec_mode_list = res.data.user_list;
                        this.showSnackbar("#4CAF50", "Rack updated successfully...");
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        axios;
                    });
            },

            isValidated() {
                if (this.editedItem.roleLinkId) {
                    return true;
                } else {
                    if (!this.editedItem.roleLinkId) {
                        this.errorMsg.roleLinkId = true;
                    }

                    return false;
                }
            }, // .....end of isValidated()
        },
    };
</script>
<style scoped>
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .v-icon-size {
        font-size: 20px;
    }
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    .edit-v-icon:hover {
        color: white;
    }
    .fields {
        padding: 0px !important;
    }
    .cus-card {
        background: #d3d3d33d;
        margin: 0rem 0.5rem 0.5rem 0.5rem;
        padding: 1rem;
        border: 2px solid gray;
    }
    .c-label {
        font-weight: bold;
    }
    .blink_me {
        white-space: nowrap;
        color: red;
        /* animation: blinker 1s linear infinite; */
        margin: 1rem;
        font-weight: bold;
        font-size: 17px;
    }
    @keyframes blinker {
        50% {
            opacity: 0;
        }
    }
</style>
